var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-data-table",
        _vm._g(
          _vm._b(
            {
              attrs: { headers: _vm.itemHeadings, items: _vm.itemsList },
              scopedSlots: _vm._u(
                [
                  _vm.title
                    ? {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-toolbar",
                              { attrs: { flat: "" } },
                              [
                                _c("v-toolbar-title", [
                                  _vm._v(_vm._s(_vm.title)),
                                ]),
                                _c("v-divider", {
                                  staticClass: "mx-4",
                                  attrs: { inset: "", vertical: "" },
                                }),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "item.actions",
                    fn: function (/* eslint-disable vue/no-unused-vars */ ref) {
                      var item = ref.item
                      return [
                        !_vm.readOnly
                          ? _c("asyent-button", {
                              attrs: {
                                "button-icon": "mdi-pencil",
                                "button-tooltip": "Edit",
                                icon: "",
                              },
                              on: {
                                confirmed: function ($event) {
                                  return _vm.editFunction(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.readOnly
                          ? _c("asyent-button", {
                              attrs: {
                                "button-icon": "mdi-eye",
                                "button-tooltip": "View",
                                icon: "",
                              },
                              on: {
                                confirmed: function ($event) {
                                  return _vm.viewFunction(item)
                                },
                              },
                            })
                          : _vm._e(),
                        !_vm.noDeleteInTable && !_vm.readOnly
                          ? _c("asyent-button", {
                              attrs: {
                                "button-icon": "mdi-delete",
                                "button-tooltip": "Delete",
                                icon: "",
                                "requires-confirmation": "",
                              },
                              on: {
                                confirmed: function ($event) {
                                  return _vm.deleteFunction(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  _vm._l(_vm.$scopedSlots, function (index, name1) {
                    return {
                      key: name1,
                      fn: function (data) {
                        return [_vm._t(name1, null, null, data)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            "v-data-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }