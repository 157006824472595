import { render, staticRenderFns } from "./asyent-simple-table.vue?vue&type=template&id=00f88137&scoped=true&"
import script from "./asyent-simple-table.vue?vue&type=script&lang=js&"
export * from "./asyent-simple-table.vue?vue&type=script&lang=js&"
import style0 from "./asyent-simple-table.vue?vue&type=style&index=0&id=00f88137&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f88137",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VDataTable,VDivider,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00f88137')) {
      api.createRecord('00f88137', component.options)
    } else {
      api.reload('00f88137', component.options)
    }
    module.hot.accept("./asyent-simple-table.vue?vue&type=template&id=00f88137&scoped=true&", function () {
      api.rerender('00f88137', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/cargo/components/asyent-simple-table.vue"
export default component.exports